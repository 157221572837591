import React, { useState, useEffect } from "react";
import { setLogin } from "../Store";
import Topic from "./topicList";
// import '../css/Topics.css'; // Archivo CSS para el estilo del listado
import "../css/Chat.css";
import "../css/App.css";
import FormTopic from "./FormTopic";
import { HOST_ENDPOINT_ADMIN_URL } from "../config/constants.config";

function ContentElements() {
  const [topics, setTopic] = useState([]);
  const [topicsFiltro, setTopicFiltro] = useState([]);
  const [filtro, setFiltro] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    id: false,
    text: '',
    urlImagen: '',
    checked: false,
    question: {
      question: '',
      options: [
        { correct: false, text: '' },
        { correct: false, text: '' },
        { correct: false, text: '' }
      ]
    }
  });
  

  const logout = () => {
    localStorage.removeItem("token");
    setLogin(false);
  };

  const newTopic = async () => {
    setFormData({
      id: false,
      text: '',
      urlImagen: '',
      question: {
        question: '',
        options: [
          { correct: false, text: '' },
          { correct: false, text: '' },
          { correct: false, text: '' }
        ]
      }
    });
  }

  // load data from api
  const loadTopics = async () => {
    const response = await fetch(HOST_ENDPOINT_ADMIN_URL+"/get-all-questions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      },
      body: JSON.stringify({}),
    });
    
    if (response.ok) {
      const data = await response.json();
      // invert array
      var list = data.reverse();
      setTopic([...list]);
      list = list.filter(topic => !topic.checked);
      setTopicFiltro([...list]);
    } else {
      console.log("Error retrieving topics");
    }
  };

  const removeTopic = async (id) => {
    // remove from array list
    const newTopics = topics.filter(topic => topic._id !== id);
    setTopic([...newTopics]);
    const newTopicsFiltro = topicsFiltro.filter(topic => topic._id !== id);
    setTopicFiltro([...newTopicsFiltro]);
  }

    

  const handleTopicClick = (id, action) => {
    // find in topics the topic with _id
    const topic = topics.find(topic => topic._id === id);
    if(!topic) {
      alert('Error: intente nuevamente');
      return;
    }
    if(action === 'edit') {
      console.log(topic);
      setFormData({
        id: topic._id,
        text: topic.text,
        urlImagen: topic.urlImagen ?? '',
        question: {
          question: topic?.question?.question || '',
          options: topic?.question?.options || [
            { correct: false, text: '' },
            { correct: false, text: '' },
            { correct: false, text: '' }
          ]
        }
      });
    }
  };

  const filtrar = () => {
    setFiltro(!filtro);
    // filtrar solo checked=true
    if(!filtro) {
      setTopicFiltro([...topics.filter(topic => topic.checked)]);
      return;
    }
    setTopicFiltro([...topics.filter(topic => !topic.checked)]);
  }


  useEffect(() => {
    loadTopics();
  }, []);

  return (
    <div className="chat">
      <div className="chat-header">
        <button className="new-conversation-button" onClick={newTopic}>New Topic</button>
        <button className="logout-button" onClick={logout}>Logout</button>
      </div>
      <div className="content-wrapper">
        <div className="menu-column">
          <FormTopic newTopic={newTopic} sending={sending} setSending={setSending} onTopicClick={handleTopicClick} formData={formData} setFormData={setFormData} loadTopics={loadTopics} ></FormTopic>
        </div>
        <div className="topics-column">
          <h3>Total: {topics.length}/{topicsFiltro.length}</h3>
          <div> Marcados: <input type="radio" checked={filtro} onChange={filtrar} /> | Sin marcar <input type="radio" checked={!filtro} onChange={filtrar} /> </div>
          <hr></hr>
          <ul className="elementos-lista">
            {topicsFiltro.map((topic, index) => (
              <Topic key={index} topic={topic} sending={sending} loadTopics={loadTopics} setSending={setSending} onTopicClick={handleTopicClick} removeTopic={removeTopic} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default ContentElements;