import React from "react";
import { RiEdit2Line, RiDeleteBinLine, RiCheckLine } from 'react-icons/ri';
import {HOST_ENDPOINT_ADMIN_URL} from '../config/constants.config';

function Topic({ topic, onTopicClick, sending, setSending, removeTopic, loadTopics, key }) {
  const urlEnpoint = HOST_ENDPOINT_ADMIN_URL;

  const handleEdit = () => {
    // Lógica para editar el topic
    onTopicClick(topic._id, 'edit');
  };

  const handleDelete = () => {
    if(!window.confirm('¿Está seguro que desea eliminar el topic?')) {
      return;
    }
    deleteTopic(topic._id);
  };

  const deleteTopic = async (id) => {
    if (sending) return;
    setSending(true);
    const data = JSON.stringify({
      card: { id },
    });
  
    try {
      const response = await fetch(urlEnpoint + "/delete-card", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: data,
      });
  
      if (response.ok) {
        // const data = await response.json();
        removeTopic(id);
        alert("Se ha eliminado correctamente");
        setSending(false);
        loadTopics();
      } else {
        console.log("Error deleting topic");
        setSending(false);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Ocurrió un error al realizar la operación");
      setSending(false);
    }
  };
  
  
  const handleCheck = async () => {
    if (sending) return;
    setSending(true);
    const data = JSON.stringify({
      card: { id: topic._id, checked: !topic.checked },
    });
  
    try {
      const response = await fetch(urlEnpoint + "/check-card", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: data,
      });
  
      if (response.ok) {
        // const data = await response.json();
        alert("Se ha marcado correctamente");
        setSending(false);
        loadTopics();
      } else {
        alert("Error deleting topic");
        setSending(false);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Ocurrió un error al realizar la operación");
      setSending(false);
    }
  };
  

    return (
      <li key={topic._id} className="elemento">
        <div className="topic-text">{topic.text}</div>
        <br />
        <div className="topic-buttons">
          <button disabled={sending} className="edit-button" onClick={handleEdit}>
            <RiEdit2Line />
          </button>
          <button disabled={sending} className="delete-button" onClick={handleDelete}>
            <RiDeleteBinLine />
          </button>
          { !topic.checked ? 
            <button disabled={sending} className="edit-button" onClick={handleCheck}>
              <RiCheckLine />
            </button>
            :
            <button disabled className="edit-button">
              <RiCheckLine />
            </button>
          }
        </div>
        { topic.urlImagen && <img src={topic.urlImagen} alt="Imagen" className="topic-image" /> }
      </li>
    );
}

export default Topic;