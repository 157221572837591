import {HOST_ENDPOINT_ADMIN_URL} from './config/constants.config';

const API_URL = HOST_ENDPOINT_ADMIN_URL;

export const sendMessageToApi = async (messageContent) => {
  try {
    const response = await fetch(API_URL+'/query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      },
      body: JSON.stringify({ prompt: messageContent }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return 'Error al enviar el mensaje';
  }
};

export const sendStartConversationToApi = async (messageContent) => {
    try {
      const response = await fetch(API_URL+'/start_conversation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
        body: JSON.stringify({ prompt: messageContent }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      return 'Error al enviar el mensaje';
    }
  };

  export const authenticateUser = async (email, password) => {
    try {
      const response = await fetch(API_URL+'/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if(response.status === 200){
          const data = await response.json();
          return data;
      }else{
            return null;
      }
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }