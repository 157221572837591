import React, { useState } from "react";
import { authenticateUser } from "./apiService";
import { setLogin, setUser } from "./Store";
import "./css/Login.css";
import jwt_decode from "jwt-decode"; 



const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [textBotton, setTextBotton] = useState('Login');

  const handleSubmit = (event) => {
    event.preventDefault();
    setTextBotton('Espere...');
    authenticateUser(username, password).then((response) => {
      if (response) {
        const user = jwt_decode(response.token);
        console.log('user...', user);
        setUser(user);
        localStorage.setItem("token", response.token);
        setLogin(true);
      }else{
        setTextBotton('Login');
        setError("Invalid username or password");
      }
    });
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <h1>Login</h1>
        <div className="form-control">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>
        <div className="form-control">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        {error && <div className="error">{error}</div>}
        <button disabled={textBotton=='Espere...'} type="submit">{textBotton}</button>
      </form>
    </div>
  );
};

export default Login;