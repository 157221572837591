import React, {useEffect, useState} from 'react';
import MediaCard from './card.students';
import QuestionCard from './question.students';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getCards, saveCards, saveCardsAnswer } from './service.students';
import {setLoading} from '../Store';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


export default function AprendiendoContent() {
  const [showButton, setShowButton] = useState(false);
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState(false);
  const [cardKey, setCardKey] = useState(0);
  const [key, setKey] = useState(0);
  const [textBotton, setTextBotton] = useState('Siguiente');
  const [step, setStep] = useState(0);
  const [cardsShowing, setCardsShowing] = useState([]);
  const limitCardsToquestion = 2;

  const handleEnableButton = () => {
    setShowButton(true);
  }

  const handleEnableAnswered = (result) => {
    // save answer
    saveCardsAnswer(card, result).then(() => {
      // console.log('-->',cardsShowing.length);
      // setCardsShowing([]);
      // setStep(0);
      // nextCard();
      // delete first card from cardsShowing
      setCardsShowing((prevCards) => prevCards.slice(1));
    });
  }

  const loadData = async () => {
    console.log('Getting data');
    setLoading(true);
    getCards().then((data) => {
      setCards(data);
      setCard(data[0]);
      setLoading(false);
      console.log('Terminado...');
    });
  };

  const handleNextCard = () => {
    console.log('next card');
    setTextBotton('Espere...');
    saveCards(card).then(() => {
      setCardsShowing((prevCards) => [...prevCards, card]);
    });
  }

  const nextCard = () => {
    const nextKey = cardKey + 1;
    setCardKey(prevKey => prevKey + 1);
    setKey(prevKey => generandomNUmber());
    setCard(cards[nextKey]);
    setShowButton(false);
    setTextBotton('Siguiente');
  };

  // eslint-disable-next-line
  useEffect(() => {
    if(cards.length === 0){
      loadData();
    }else{
      if(step == 0){
        if (cardsShowing.length >= limitCardsToquestion) {
          const number = generandomNUmber();
          console.log('Step0.1 --> ', number);
          setCard(cardsShowing[0]);
          setKey(number);
          setStep(1);
        }else{
          console.log('Step0.2')
          nextCard();
        }
      }else if(step === 1){
        if(cardsShowing.length === 0){
          console.log('Step1.1')
          setCardsShowing([]);
          setStep(0);
          nextCard();
        }else{
          const number = generandomNUmber();
          console.log('Step1.2 -> ', number);
          setKey(number);
          setCard(cardsShowing[0]);
          setShowButton(false);
          setTextBotton('Siguiente');
        }
      }
    }
  }, [cardsShowing]); // eslint-disable-next-line

  const generandomNUmber = () => {
    // from 150 to 9847284
    return Math.floor(Math.random() * (9847284 - 150 + 1)) + 150;
  }

  return (
    <div>
      {card && (
        (step === 0) ? <MediaCard key={key} card={card} handleEnableButton={handleEnableButton} />
        :
        (cardsShowing.length>0) && <QuestionCard key={key} card={card} handleEnableButton={handleEnableAnswered} />
      )}
      { showButton && (step===0) && 
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
          <Button disabled={textBotton === 'Espere...'} variant="contained" onClick={handleNextCard} endIcon={<NavigateNextIcon />}>
            {textBotton}
          </Button>
        </Box>
      }
      { (cards.length == 0) && 
        <Stack spacing={1}>
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    
          {/* For other variants, adjust the size with `width` and `height` */}
          {/* <Skeleton variant="circular" width={40} height={40} /> */}
          <Skeleton variant="rectangular" width={210} height={60} />
          <Skeleton variant="rounded" width={210} height={60} />
          <h6>Esperando datos...</h6>
        </Stack>
      }
    </div>
  );
}