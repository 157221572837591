import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LogoutIcon from '@mui/icons-material/Logout';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AprendiendoContent from './aprendiendo.content';
import {setLogin} from '../Store';

const MainStudents = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const logout = () => {
    localStorage.removeItem("token");
    setLogin(false);
  };

  const actions = [
    { icon: <LogoutIcon />, name: 'Salir', onclick: logout }
  ];

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm" style={{height: "90vh", width:"400px"}}>
        <Box sx={{ 
        height: '100%', // Cambia a 100% para llenar el contenedor
        transform: 'translateZ(0px)', 
        flexGrow: 1,
        display: 'flex', // Agrega Flexbox
        flexDirection: 'column', // Alinea los elementos verticalmente
        // justifyContent: 'flex-end' // Alinea el último elemento (SpeedDial) al final
      }}>
        <h3>Aprendiendo</h3>
          <AprendiendoContent />
          <Backdrop open={open} />
          <SpeedDial
            ariaLabel="Menú de opciones"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={action.onclick}
              />
            ))}
          </SpeedDial>
        </Box>
      </Container>
    </React.Fragment>
  );
}
export default MainStudents;