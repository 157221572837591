import {React, useEffect} from "react";
import { observer } from "mobx-react";
import { store, setLogin } from "./Store";
import  Login  from "./Login";
// import Chat from "./Chat";
import ContentElements from "./lenglish/ContentElements";
import MainStudents from "./students/main.students";
import Loading from './utils/loading.utils';

const App = observer(() => {

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (token && !store.login) {
        setLogin(true);
      }
      console.log('store...', store)
    }, []);

  return (
    <div className="app-container">
      {store.login ? (
         store.user.isAdmin? <ContentElements></ContentElements> : <MainStudents></MainStudents>
      ) : (
        <Login />
      )}
      <Loading />
    </div>
  );
});

export default App;
