import React, { useState } from 'react';
import {HOST_ENDPOINT_ADMIN_URL} from '../config/constants.config';

const FormTopic = ({formData, setFormData, sending, setSending, loadTopics, newTopic}) => {
  const urlEnpoint = HOST_ENDPOINT_ADMIN_URL;
  // const urlEnpoint = 'http://localhost:3005';
  const [errorMessage, setErrorMessage] = useState(null);

  const handleInputChange = (event, index = null) => {
    if(index !== null) {
      // console.log('11')
      const newOptions = [...formData.question.options];
      newOptions[index][event.target.name] = event.target.name === 'correct' ? event.target.checked : event.target.value;
      setFormData({
          ...formData,
          question: {
            ...formData.question,
            options: newOptions
          }
        });
      } else {
        // console.log('22', event.target.name);
        if (event.target.name === 'text') {
          setFormData({
            ...formData,
            text: event.target.value
          });
        } else if (event.target.name === 'urlImagen') {
          setFormData({
            ...formData,
            urlImagen: event.target.value
          });
        }else{
          setFormData({
            ...formData,
            question: {
              ...formData.question,
              [event.target.name]: event.target.value
            }
          });
        }
    }
  };

  const handleRadioChange = (index) => {
    const newOptions = formData.question.options.map((option, i) => ({
      ...option,
      correct: i === index // Establece la opción correcta correspondiente a true
    }));
  
    setFormData({
      ...formData,
      question: {
        ...formData.question,
        options: newOptions
      }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if(sending) return;
    const validationError = validateForm(formData);
    if(validationError) {
      handleErrorMessage(validationError);
      return;
    }
    setSending(true);

    if(formData.id) {
      updateData(formData);
    } else {
      sendData(formData);
    }
    // add formData to general array
  };


  const sendData = async (obj) => {
    try {
      const data = JSON.stringify({
        card: obj
      });
  
      const respuesta = await fetch(urlEnpoint+'/save-card', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
        body: data
      });
  
      const respuestaJSON = await respuesta.json();
      console.log(respuestaJSON); // Puedes hacer algo con la respuesta recibida si lo deseas
      loadTopics();
      newTopic();
      setSending(false);
      alert('Se ha creado correctamente');
    } catch (error) {
      console.error('Error al enviar el objeto JSON:', error);
      // newTopic();
      setSending(false);
      alert('Error al enviar el objeto JSON');
    }
  };

  const updateData = async (obj) => {
    try {
      const data = JSON.stringify({
        card: obj
      });
  
      const respuesta = await fetch(urlEnpoint+'/update-card', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
        body: data
      });
  
      const respuestaJSON = await respuesta.json();
      console.log(respuestaJSON); // Puedes hacer algo con la respuesta recibida si lo deseas
      loadTopics();
      newTopic();
      setSending(false);
      alert('Se ha actualizado correctamente');
    } catch (error) {
      console.error('Error al enviar el objeto JSON:', error);
      // newTopic();
      setSending(false);
      alert('Error al enviar el objeto JSON');
    }
  };

  const handleErrorMessage = (message) => {
    setErrorMessage(message);
    // Después de 5 segundos, limpia el mensaje de error
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  // Función de validación
const validateForm = (formData) => {
  if(formData.text.trim() === '') {
    return 'El texto no puede estar vacío';
  }

  if(formData.urlImagen.trim() === '') {
    return 'La url de imagen no puede estar vacía';
  }

  if(formData.question.question.trim() === '') {
    return 'La pregunta no puede estar vacía';
  }

  let hasCorrectOption = false;
  for(let i=0; i<formData.question.options.length; i++) {
    if(formData.question.options[i].text.trim() === '') {
      return `La opción ${i+1} no puede estar vacía`;
    }

    if(formData.question.options[i].correct) {
      hasCorrectOption = true;
    }
  }

  if(!hasCorrectOption) {
    return 'Debe haber al menos una opción correcta';
  }

  // Si todas las validaciones pasan, devuelve null
  return null;
};

  return (
    <form className="form-topic" onSubmit={handleSubmit}>
      {errorMessage && <div className="error">{errorMessage}</div>}
      <label>
        Texto:
        <textarea name="text" rows={10} value={formData.text} onChange={handleInputChange} />
      </label>
      <label>
        Imagen:
        <input type="text" name="urlImagen" value={formData.urlImagen} onChange={handleInputChange} />
      </label>
      <label>
        Pregunta:
        <input type="text" name="question" value={formData.question.question} onChange={handleInputChange} />
      </label>
      {formData.question.options.map((option, index) => (
        <div key={index}>
          <label>
            Opción {index + 1}:
            <input type="text" name="text" value={option.text} onChange={(e) => handleInputChange(e, index)} />
          </label>
          <label>
            Correcta:
            <input
              type="radio"
              name="correct"
              checked={option.correct}
              onChange={() => handleRadioChange(index)}
            />
          </label>
        </div>
      ))}
      <button disabled={sending} type="submit">{ (formData.id) ? 'Actualizar':'Crear Nuevo' }</button>
    </form>
  );
};

export default FormTopic;
