import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { store } from '../Store';

const Loading = () => {
  return (
    store.loading && 
    <div style={{zIndex:99999999}}>
      <Backdrop open={true} style={{zIndex: 9999999}}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};

export default Loading;