import { observable, action } from "mobx";

const store = observable(
  {
    login: false,
    user: {},
    loading: false,
    pageActive: 'aprender',
  },
  {},
  { deep: true }
);
const setLogin = action((val) => { store.login = val; });

const setUser = action((val) => { store.user = val; })

const setLoading = action((val) => { store.loading = val; });

const setPageActive = action((val) => { store.pageActive = val; });



export {
  store,
  setLogin,
  setUser,
  setLoading,
  setPageActive
}