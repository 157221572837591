import React,{useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function QuestionCard({card, handleEnableButton}) {

  const [buttons, setButtons] = React.useState();
  const [textMessage, setTextMessage] = React.useState('Selecciona la respuesta correcta');

  var blockButton = false;

  console.log(card);

  const addButtons = (goodAnswer, badAnswer) => {
    setButtons(card.question.options.map((option, index) => {
      var color = 'info';
      color = (goodAnswer === index) ? 'success' : color;
      color = (badAnswer === index) ? 'error': color;
      return <Button
        key={index.toString()}
        style={{
          margin: '8px ',
          borderBottom: '1px solid',
        }}
        onClick={() => handleButton(option, index)}
        color={ (option.correct && badAnswer >= 0)? 'success':color}
      >
        {option.text}
      </Button>
    }));
  }

  const handleButton = (option, index) => {
    if(blockButton) return;
    blockButton = true;

    if (option.correct) {
      setTextMessage('¡Correcto! Espere...');
      addButtons(index, -1);
      handleEnableButton(true);
    } else {
      setTextMessage('¡Incorrecto! Espere...');
      addButtons(-1, index);
      handleEnableButton(false);
    }
  }

// eslint-disable-next-line
  useEffect(() => {
    addButtons();
  }, []);


  return (
    <Card sx={{ maxWidth: 345 }} style={{zIndex: 3}}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Pregunta:
        </Typography>
        <Typography variant="h6" color="text.primary">
            {card.question.question}
        </Typography>
      </CardContent>
      <Typography variant="caption" color="text.primary" style={{marginLeft:'20px'}}>
            {textMessage}
        </Typography>
      <Box
        sx={{
          display: 'flex',
          '& > *': {
            m: 1,
          },
        }}
      >
        <ButtonGroup
          orientation="vertical"
          aria-label="vertical contained button group"
          variant="contained"
        >
          {buttons}
        </ButtonGroup>
      </Box>
    </Card>
  );
}