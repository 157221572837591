import {HOST_ENDPOINT_USER_URL} from '../config/constants.config';

export const getCards = async (messageContent) => {
  try {
    const response = await fetch(HOST_ENDPOINT_USER_URL+'/get-cards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      },
      body: JSON.stringify({ prompt: messageContent }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return 'Error al enviar el mensaje';
  }
};

export const saveCards = async (card) => {
  try {
    const response = await fetch(HOST_ENDPOINT_USER_URL+'/save-card', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      },
      body: JSON.stringify({ cardId: card._id }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return 'Error al enviar el mensaje';
  }
};

export const saveCardsAnswer = async (card, answer) => {
  try {
    const response = await fetch(HOST_ENDPOINT_USER_URL+'/save-answer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      },
      body: JSON.stringify({ cardId: card._id, answer: answer }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return 'Error al enviar el mensaje';
  }
}