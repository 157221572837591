import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { TypeAnimation } from 'react-type-animation';

export default function MediaCard({card, handleEnableButton}) {
  // console.log(card.text);
  return (
    <Card sx={{ maxWidth: 345 }} style={{zIndex: 3}}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Dato Importante:
        </Typography>
        <TypeAnimation
          sequence={[
            card.text,
            100,
            () => {
              handleEnableButton();
            }
          ]}
          cursor={false}
          omitDeletionAnimation={true}
          repeat={0}
          style={{ fontSize: '1em', display: 'inline-block', color:"text.secondary" }}
        />

      </CardContent>
      <CardMedia
        sx={{ height: 200 }}
        image={card.urlImagen || 'https://media.istockphoto.com/id/1438300833/photo/climate-change-and-recycling.jpg?s=612x612&w=0&k=20&c=6J7IEkWDlxFlykXPsTFTsjFiYah2O2qc-fhqkQVn0PI='}
        title="Card"
      />
    </Card>
  );
}